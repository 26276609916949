// Variables
$pink-500: #ec4899;
$pink-600: #db2777;
$gray-100: #f3f4f6;
$gray-600: #4b5563;
$red-500: #ef4444;
$border-color: #e5e7eb;

.header {
  border-bottom: 1px solid $border-color;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin: 0 auto;
    max-width: 1000px;
    @media (min-width: 768px) {
      padding: 1rem;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;

    .logo {
      width: 100%;
      max-width: 200px;
      height: auto;
    }
  }

  .header-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &.outline-button {
    background-color: transparent;
    border: 1px solid $border-color;
    color: $gray-600;

    &:hover {
      background-color: $gray-100;
    }
  }

  &.signup-button,
  &.cta-button,
  &.submit-button {
    background-color: $pink-500;
    color: white;
    border: none;

    &:hover {
      background-color: $pink-600;
    }
  }
}
