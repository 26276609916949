.footer {
  background-color: white;
  border-top: 1px solid #eaeaea;
  .inner {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__links {
    display: flex;
    gap: 20px;

    a {
      text-decoration: none;
      color: $text-color;
      font-size: 14px;

      &:hover {
        color: darken($text-color, 10%);
      }
    }
  }

  &__copyright {
    font-size: 14px;
    color: $text-color;
  }
}
