.auth-page {
  padding: 2rem 0;

  .auth-form {
    background-color: white;
    border-radius: $border-radius;
    padding: 2rem;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    h2 {
      color: $text-color;
      margin-bottom: 1rem;
      text-align: center;
    }

    .error-message {
      color: red;
      margin-bottom: 1rem;
    }

    .form-group {
      margin-bottom: 1rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
      }

      input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: $border-radius;
      }
    }

    button {
      width: 100%;
      background-color: $button-color;
      color: white;
      border: none;
      padding: 0.75rem;
      border-radius: $border-radius;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($button-color, 10%);
      }
    }

    .toggle-auth {
      margin-top: 1rem;
      text-align: center;

      button {
        background: none;
        border: none;
        color: $primary-color;
        cursor: pointer;
        padding: 0;
        font-size: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 300px;
  width: 100%;
  text-align: center;
}

.dialog button {
  margin-top: 10px;
}

.auth-page {
  .auth-form {
    .message {
      margin-bottom: 1rem;
      padding: 0.5rem;
      border-radius: $border-radius;
      text-align: center;

      &.success {
        background-color: #d4edda;
        color: #155724;
      }

      &.error {
        background-color: #f8d7da;
        color: #721c24;
      }
    }

    .toggle-auth {
      margin-top: 1rem;
      text-align: center;

      a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  p.forgot-password {
    margin: 2rem auto 0;
    text-align: center;
    a {
      display: block;
    }
  }
}
.password-rules  p{
  font-size: 14px;
  margin: 0 auto 12px;
  color: #848484;
}