.terms-container {
  max-width: 1000px;
  margin: 40px auto;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }

  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
    color: #333333;
  }

  h2 {
    margin-top: 20px;
    font-size: 1.5em;
    color: #444444;
  }

  p {
    margin: 10px 0 20px;
    line-height: 1.6;
    color: #555555;
  }

  .back-to-home {
    text-align: center;
    margin-top: 30px;

    a {
      text-decoration: none;
      color: $text-color;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}