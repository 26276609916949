@import 'variables';

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Global styles
body {
  font-family: 'Arial', sans-serif;
  background-color: $base-color;
  color: $text-color;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; // ビューポートの高さを基準にする
}

.main-content {
  flex: 1; // メインコンテンツが余分なスペースを占める
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}



@import 'navigation';
@import 'adminDashboard';
@import 'uploadPage';
@import 'authPage';
@import 'viewPage';
@import 'imageGallery';
@import 'errorPage';
@import 'landingPage';
@import 'termsOfService';
@import 'header';
@import 'footer';


// Responsive styles
@media (max-width: 768px) {
  nav {
    ul {
      flex-direction: column;

      li {
        margin: 0.5rem 0;
      }
    }

    .user-info {
      flex-direction: column;
      align-items: flex-start;

      span {
        margin-bottom: 0.5rem;
      }
    }
  }

  .upload-page {
    .upload-section {
      padding: 1rem;

      button {
        margin-left: 0;
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
}

