// Navigation styles
nav {
  background-color: white;
  padding: 1rem 0;
  border-bottom: 1px solid #e0e0e0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: row-reverse;
    }
  }

  .nav-header {
    display: none;
  }

  #checkbox {
    display: none;
  }

  .toggle {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition-duration: 0.5s;
  }

  .bars {
    width: 100%;
    height: 4px;
    background-color: $text-color;
    border-radius: 4px;
  }

  #bar2 {
    transition-duration: 0.8s;
  }

  #bar1,
  #bar3 {
    width: 70%;
  }

  #checkbox:checked + .toggle .bars {
    position: absolute;
    transition-duration: 0.5s;
  }

  #checkbox:checked + .toggle #bar2 {
    transform: scaleX(0);
    transition-duration: 0.5s;
  }

  #checkbox:checked + .toggle #bar1 {
    width: 100%;
    transform: rotate(45deg);
    transition-duration: 0.5s;
  }

  #checkbox:checked + .toggle #bar3 {
    width: 100%;
    transform: rotate(-45deg);
    transition-duration: 0.5s;
  }

  #checkbox:checked + .toggle {
    transition-duration: 0.5s;
    transform: rotate(180deg);
  }

  .nav-box {
    display: flex;
    align-items: center;
    gap: 1rem;
    .logo {
      width: 180px;
    }
  }

  .nav-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    @include flex-center;
    flex-wrap: wrap;

    li {
      margin: 0 1rem;

      a.nav-link {
        color: $text-color;
        text-decoration: none;
        font-weight: bold;
        transition: color 0.3s ease;

        &:hover {
          color: darken($text-color, 20%);
        }
      }
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    color: $text-color;

    span {
      margin-right: 1rem;
    }

    .logout-button {
      position: relative;
      background-color: transparent;
      color: $text-color;
      border: none;
      padding: 0.2rem 0.5rem;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      overflow: visible;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: width 0.3s ease, height 0.3s ease;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);

        &::before {
          width: 120%;
          height: 120%;
        }

        .tooltip {
          opacity: 1;
          transform: translateY(-50%);
        }
      }

      i {
        position: relative;
        z-index: 2;
      }

      .tooltip {
        position: absolute;
        top: 50%;
        left: calc(100% + 10px);
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 0.3rem 0.7rem;
        border-radius: 4px;
        font-size: 0.8rem;
        white-space: nowrap;
        opacity: 0;
        transition: all 0.3s ease;
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 100%;
          transform: translateY(-50%);
          border-width: 5px 5px 5px 0;
          border-style: solid;
          border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .nav-header {
      display: flex;
      justify-content: flex-end;
    }

    .nav-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: 0;
      background-color: white;
      padding: 1rem 0;

      &.open {
        display: flex;
        z-index: 1;
      }

      li {
        margin: 1rem 0;
      }
    }

    .user-info {
      flex-direction: column;
      align-items: flex-start;
      button {
        margin: 12px auto 0;
      }

      span {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.share-url-container {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;


  .share-url-input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #333;
    border-radius: $border-radius;
    font-size: 0.9rem;
    background-color: rgba(255, 255, 255, 0.1);
    color: $text-color;
    max-width: 90px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .share-url-actions {
    display: flex;
    margin-left: 0.5rem;
  }

  .action-button {
    background-color: transparent;
    border: none;
    color: $text-color;
    font-size: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &.copy-button:hover {
      color: darken($button-color, 10%);
    }

    &.delete-button:hover {
      color: darken($delete-color, 10%);
    }
  }

  .generate-button {
    background-color: $button-color;
    position: relative;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: $border-radius;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background-color: darken($button-color, 10%);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      .tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    i {
      font-size: 1rem;
    }
    .fa-arrow-up-from-bracket {
      font-size: .8rem;
    }
    .tooltip {
      position: absolute;
      bottom: -40px; // ボタンの下に配置
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 1);
      color: white;
      padding: 0.3rem 0.7rem;
      border-radius: 4px;
      font-size: 0.8rem;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      pointer-events: none;
      z-index: 1000;

      &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 0 5px 5px 5px;
        border-style: solid;
        border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
      }
    }
  }
}


@media (max-width: 768px) {
  .share-url-container {
    align-items: stretch;

    .share-url-input {
      margin-bottom: 0.5rem;
    }

    .share-url-actions {
      justify-content: space-between;
      margin-left: 0;
    }
  }
}
