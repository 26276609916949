.view-page-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
}
.background-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.images-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image-container {
  position: absolute;
  width: 15%;
  height: 15%;
  will-change: transform;
}

.animated-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: none;
  will-change: transform;
}

.emote {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  animation: float 2s ease-in-out infinite;
  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes float {
  0%, 100% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-10px); }
}

.image-counter {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 90px;
  height: 90px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  z-index: 900;
  p {
    line-height: 1;
    font-size: 1.6rem;
  }
  span {
    font-size: .7rem;
  }
}

.speed-slider-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.speed-slider {
  flex-grow: 1;
  margin: 0 10px;
}

.speed-label {
  font-size: 0.8em;
  color: $text-color;
}

.speed-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 0.8em;
  color: $text-color;
}

.moving {
  z-index: 999;
}

.new-image-animation {
  z-index: 1000;
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes newImageAnimation {
  0% {
    transform: translate(-50%, -50%) scale(0.1) perspective(500px) translateZ(-1000px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2) perspective(500px) translateZ(50px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1) perspective(500px) translateZ(0);
    opacity: 1;
  }
}

button.bgm-control {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 900;
  background: rgba(0, 0, 0, 0);
}

.audio-permission-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  z-index: 1000;
  backdrop-filter: blur(5px);

  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 25px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 15px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 25px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s ease;

      &:first-child {
        background-color: $button-color;
        color: white;
        &:hover {
          background-color: darken($button-color, 10%);
        }
      }

      &:last-child {
        background-color: $secondary-color;
        color: white;
        &:hover {
          background-color: darken($secondary-color, 10%);
        }
      }
    }
  }
}