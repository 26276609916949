.admin-dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;

  h1 {
    margin-bottom: 20px;
    color: #333;
  }

  .dashboard-content {
    display: flex;
    gap: 20px;
  }

  .user-list {
    flex: 1;
    overflow-x: auto;

    h2 {
      margin-bottom: 15px;
    }

    .search-form {
      display: flex;
      margin-bottom: 15px;
      gap: 10px;

      input[type="text"] {
        flex: 1;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      select {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      button {
        padding: 8px 15px;
        background-color: $button-color;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: darken($button-color, 10%);
        }
      }
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      overflow: hidden;

      th,
      td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid #e0e0e0;
      }

      th {
        background-color: #f8f9fa;
        font-weight: bold;
        color: #333;
        text-transform: uppercase;
        font-size: 0.85em;
        letter-spacing: 0.5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #e9ecef;
        }

        svg {
          margin-left: 5px;
          vertical-align: middle;
        }
      }

      tr:last-child td {
        border-bottom: none;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      tr:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      font-size: 1.2em;
      color: #666;

      .spinner {
        margin-right: 10px;
        animation: spin 1s linear infinite;
      }
    }

    .error {
      color: #d32f2f;
      text-align: center;
      margin: 20px 0;
    }

    .load-more {
      display: block;
      width: 200px;
      margin: 20px auto;
      padding: 10px;
      background-color: $button-color;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: darken($button-color, 10%);
      }
    }
  }
  .user-details-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .popup-content {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      width: 80%;
      max-width: 600px;
      max-height: 80vh;
      overflow-y: auto;
      position: relative;

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.5em;
        cursor: pointer;
        color: #333;

        &:hover {
          background-color: unset;
        }
      }

      h2 {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
    .user-details {
      flex: 1;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 5px;

      h2 {
        margin-bottom: 15px;
      }

      div {
        margin-bottom: 10px;

        label {
          display: inline-block;
          width: 150px;
          font-weight: bold;
        }

        input,
        select {
          width: calc(100% - 160px);
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 3px;
        }
      }

      button {
        margin-top: 10px;
        padding: 8px 15px;
        background-color: $button-color;
        color: white;
        border: none;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
          background-color: darken($button-color, 10%);
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
