.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: $bg-color;
  font-family: Arial, sans-serif;

  h1 {
    font-size: 3rem;
    color: $delete-color;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: $text-color;
    margin-bottom: 2rem;
    max-width: 80%;
  }

  a {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: $button-color;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color:  darken($button-color, 10%);
    }
  }
}