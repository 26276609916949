.upload-page {
  padding: 2rem 0 1rem;

  h2 {
    color: $text-color;
    margin-bottom: 1rem;
  }
  .container {
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .m-top {
      margin-top: 1rem;
    }
  }

  .upload-section {
    background-color: white;
    border-radius: $border-radius;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    width: calc(100% / 3 - 2%);
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .step {
      font-weight: 600;
      font-size: 1.2em;
      line-height: 1;
      margin-bottom: 10px;
      color: $secondary-color;
    }

    input[type="file"] {
      display: none;
    }
    select {
      width: 100%;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }

    .file-input-label {
      display: inline-block;
      background-color: $confirm-color;
      color: $text-color;
      padding: 0.5rem 1rem;
      border-radius: $border-radius;
      cursor: pointer;
      transition: background-color 0.3s ease;
      line-height: 1.5;
      text-decoration: none;

      &:hover {
        background-color: darken($confirm-color, 10%);
      }
    }
    .file-input-label-b {
      display: inline-block;
      background-color: $secondary-color;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: $border-radius;
      cursor: pointer;
      transition: background-color 0.3s ease;
      line-height: 1.5;
      text-decoration: none;

      &:hover {
        background-color: darken($secondary-color, 10%);
      }
    }

    button {
      background-color: $button-color;
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: $border-radius;
      cursor: pointer;
      transition: background-color 0.3s ease;
      line-height: 1.5;

      &:hover {
        background-color: darken($button-color, 10%);
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
      &.delete-btn {
        margin-left: 0;
        color: $delete-color;
        background-color: #00000000;
        padding: .2rem 0;
        &:hover {
          background-color: #00000000;
        }
        @media screen and (max-width: 768px) {
          width: auto;
        }
      }
    }
    .note {
      padding: 10px;
      font-size: 0.9em;
      line-height: 1.5;
      background-color: $bg-color;
      border-radius: $border-radius;
      margin-top: 20px;
      p {
        margin-bottom: 0.3rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .share-url-container {
      .generate-button:hover {
        box-shadow: none;
      }
      .share-url-input {
        color: $text-color;
        max-width: 100%;
        @media screen and (max-width: 768px) {
          margin: 0 .5rem 0 0;
        }
      }
      .share-url-actions {
        gap: 0.5rem;
        .copy-button, .delete-button {
          background-color: #00000000;
          padding: 0.1rem 0.2rem;
          font-size: 1.2rem;
          @media screen and (max-width: 768px) {
            width: auto;
            margin-top: 0;
          }
        }
        .copy-button {
          color: $button-color;
        }
        .delete-button {
          color: $delete-color;
        }
      }
    }
  }
  .fa-up-right-from-square {
    font-size: .8rem;
  }
  .upload-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    flex-direction: column;
    .m-top {
      margin-top: 2rem;
    }
    .upload-controls {
      display: flex;
      gap: 1rem;
      @media screen and (max-width: 768px) {
        width: 100%;
        .upload-button {
          margin-top: 0;
          max-width: 50%;
        }
        label.file-input-label {
          width: 50%;
          text-align: center;
        }
      }
    }
  }

  .background-preview {
    max-width: 360px;
    padding: 1rem;
    background-color: $bg-color;
    border-radius: 4px;
    margin: 0 auto 1rem;
    text-align: right;
    img {
      max-width: 100%;
      height: auto;
      border-radius: $border-radius;
      margin-bottom: 1rem;
      max-height: 200px;
    }
    video {
      max-width: 100%;
      width: 360px;
    }
  }
  .file-info {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #f0f0f0;
    border-radius: $border-radius;
    font-size: 0.9em;
    color: $text-color;
    max-width: 100%;

    .file-icon {
      margin-right: 0.5rem;
      font-size: 1.2em;
    }

    .file-name {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-clear {
      background: none;
      border: none;
      color: $text-color;
      cursor: pointer;
      padding: 0;
      font-size: 1.2em;
      margin-left: 0.5rem;
      width: auto;
      margin-top: 0px;
      &:hover {
        background-color: unset;
      }
    }
  }
  .progress-and-delete-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
    .delete-btn {
      margin-top: 0;
      padding: 0;
      background-color: #00000000;
     .fa-trash-alt {
        font-size: 1.2rem;
        color: $delete-color;
     }
    }
  }
}

.settings-container {
  border-radius: 10px;
  margin: 20px auto;

  h3 {
    margin-bottom: 15px;
  }

  .setting-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    select {
      width: 100%;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }

  .random-button {
    background-color: $button-color;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto 1rem;

    &:hover {
      background-color: darken($button-color, 10%);
    }
  }
}

.speed-slider-container {
  width: 100%;
  padding: 10px 0;

  .speed-slider {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $button-color;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $button-color;
      cursor: pointer;
    }
  }
}

.speed-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 20px;
  background-color: $button-color;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-text {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.ground-setting-container {
  background-color: $bg-color;
}

audio {
  max-width: 100%;
}
