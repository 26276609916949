// Variables
$pink-500: #ec4899;
$pink-600: #db2777;
$gray-100: #f3f4f6;
$gray-600: #4b5563;
$red-500: #ef4444;
$border-color: #e5e7eb;

.landing-page {
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;

  .inner {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
    @media (min-width: 768px) {
      padding: 0;
    }
  }


  .button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &.outline-button {
      background-color: transparent;
      border: 1px solid $border-color;
      color: $gray-600;

      &:hover {
        background-color: $gray-100;
      }
    }

    &.signup-button, &.cta-button, &.submit-button {
      background-color: $pink-500;
      color: white;
      border: none;

      &:hover {
        background-color: $pink-600;
      }
    }
  }

  .landing-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .hero-section {
      padding: 2rem 0;

      .inner {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 768px) {
          flex-direction: row;
        }
      }

      .hero-content {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 768px) {
          width: 50%;
          margin-top: 0;
        }

        .hero-title {
          width: 100%;
          height: auto;
          max-width: 400px;
        }

        .cta-button {
          font-size: 1.125rem;
          padding: 0.75rem 2rem;
          margin-top: 1.5rem;
          border-radius: 10rem;
        }
      }

      .hero-image {
        width: 100%;

        @media (min-width: 768px) {
          width: auto;
        }

        .character-image {
          width: 100%;
          height: auto;
          max-width: 480px;
        }
      }
    }

    .info-section {
      background-color: #fffafc;
      padding: 3rem 0;

      .info-content {
        text-align: center;

        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }

        p {
          color: $gray-600;
        }
      }
    }

    .faq-section {
      padding: 3rem 0;

      h2 {
        font-size: 1.875rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
      }

      .faq-accordion {
        .faq-item {
          margin-bottom: 1rem;
          border: 1px solid $border-color;
          border-radius: 0.25rem;
          overflow: hidden;

          .faq-question {
            font-weight: bold;
            padding: 1rem;
            cursor: pointer;
            background-color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &::after {
              content: '+';
              font-size: 1.5rem;
              transition: transform 0.3s ease;
            }

            &.open::after {
              transform: rotate(45deg);
            }
          }

          .faq-answer {
            background-color: white;
            overflow: hidden;
            transition: max-height 0.3s ease, opacity 0.3s ease;
            max-height: 0;

            .faq-answer-inner {
              padding: 0 1rem 1rem 1rem;
            }

            &.open {
              max-height: 500px;
              opacity: 1;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }

    .contact-section {
      background-color: white;
      padding: 3rem 0;
      .inner {
        background-color: #f9fbf8;
        padding: 3rem 1rem;
        border-radius: 0.25rem;
      }

      h2 {
        font-size: 1.875rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
      }

      .contact-form {
        max-width: 32rem;
        margin: 0 auto;

        .form-group {
          margin-bottom: 1.5rem;

          label {
            display: block;
            margin-bottom: 0.5rem;
          }

          input, textarea {
            width: 100%;
            padding: 0.5rem;
            border: 1px solid $border-color;
            border-radius: 0.25rem;
          }

          textarea {
            min-height: 100px;
          }

          .required {
            color: $red-500;
          }
        }

        .submit-button {
          width: 100%;
        }
        .consent {
          text-align: center;
          font-size: 0.875rem;
          margin-bottom: 20px;
        }
      }
    }
  }
}