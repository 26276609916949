@import "variables";

.image-gallery {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  .gallery-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }

    .bulk-delete-button {
      padding: 8px 15px;
      border: none;
      border-radius: $border-radius;
      cursor: pointer;
      background-color: $delete-color;
      color: white;
      transition: background-color 0.3s ease;

      &:hover:not(:disabled) {
        background-color: darken($delete-color, 10%);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .gallery-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }

  .image-tile {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }

    .image-info {
      padding: 15px;
      font-size: 14px;

      p {
        margin: 5px 0;
      }
    }

    .image-actions {
      display: flex;
      justify-content: center;
      padding: 0 15px 15px;
      gap: 30px;

      button {
        padding: 8px 15px;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:first-child {
          background-color: $button-color;
          color: white;

          &:hover {
            background-color: darken($button-color, 10%);
          }
        }

        &:last-child {
          background-color: $delete-color;
          color: white;

          &:hover {
            background-color: darken($delete-color, 10%);
          }
        }
      }
    }
  }

  .edit-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 360px;
    max-width: 90%;

    .modal-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      button {
        margin-left: 10px;
        padding: 8px 15px;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
    }

    button {
      margin-top: 10px;
      padding: 8px 15px;
      border: none;
      border-radius: $border-radius;
      cursor: pointer;
      background-color: $button-color;
      color: white;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($button-color, 10%);
      }
      &.cancel-button {
        background-color: $secondary-color;
        color: white;

        &:hover {
          background-color: darken($secondary-color, 10%);
        }
      }
      &.random-button {
        background-color: $button-color;
        color: white;
        padding: 8px 15px;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($button-color, 10%);
        }
      }
      &.confirm-button {
        background-color: $confirm-color;
        color: $text-color;
        margin-left: 0;

        &:hover {
          background-color: darken($confirm-color, 10%);
        }
      }
    }
  }
}
